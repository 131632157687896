.table-left-bordered {
  .table {
      tbody {
          tr {
              position: relative;

              &:after {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 5%;
                  height: 0%;
                  background: var(--#{$variable-prefix}primary);
                  width: .25rem;
                  border-top-right-radius: $border-radius;
                  border-bottom-right-radius: $border-radius;
                  transition: 300ms ease-in-out;
              }

              &:hover {
                  &:after {
                      height: 90%;
                      transition: 300ms ease-in-out;
                  }
              }

              &:last-child {
                  td {
                      border-width: 0;
                  }
              }
          }
      }
  }
}
